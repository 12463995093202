import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { isProductConfigurable } from '@magento/peregrine/lib/util/isProductConfigurable';
export var getDimensions = function getDimensions(product) {
  return product !== null && product !== void 0 && product.hf_height && product !== null && product !== void 0 && product.hf_width && product !== null && product !== void 0 && product.hf_depth_length ? "".concat(product === null || product === void 0 ? void 0 : product.hf_height, " x ").concat(product === null || product === void 0 ? void 0 : product.hf_width, " x ").concat(product === null || product === void 0 ? void 0 : product.hf_depth_length) : null;
};
export var searchParamsToObject = function searchParamsToObject(search) {
  return Object.fromEntries(new URLSearchParams(search));
};

function preventDefault(e) {
  e.preventDefault();
}

export var addScrollToBody = function addScrollToBody() {
  var toTop = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
  var $body = document.querySelector('body');
  $body.style.removeProperty('overflow');
  document.body.removeEventListener('touchmove', preventDefault);

  if (toTop) {
    $body.style.removeProperty('position');
    $body.style.removeProperty('top');
    $body.style.removeProperty('width');
    window.scrollTo(0, 0);
  }
};
export var removeScrollFromBody = function removeScrollFromBody() {
  var _document$getElementB;

  var toTop = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
  var elementId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'scroll-element';
  document.body.addEventListener('touchmove', preventDefault, {
    passive: false
  });
  (_document$getElementB = document.getElementById(elementId)) === null || _document$getElementB === void 0 ? void 0 : _document$getElementB.addEventListener('touchmove', function (e) {
    e.stopPropagation();
  }, {
    passive: false
  });
  var $body = document.querySelector('body');
  $body.style.overflow = 'hidden';

  if (toTop) {
    $body.style.position = 'fixed';
    $body.style.top = "-".concat(window.pageYOffset, "px");
    $body.style.width = '100%';
  }
};
export var formatUsPhone = function formatUsPhone(phone) {
  var phoneTest = new RegExp(/^((\+1)|1)? ?\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})( ?(ext\.? ?|x)(\d*))?$/);
  phone = phone.trim();
  var results = phoneTest.exec(phone);

  if (results !== null && results.length > 8) {
    return '+1 ' + '(' + results[3] + ') ' + results[4] + '-' + results[5] + (typeof results[8] !== 'undefined' ? ' x' + results[8] : '');
  } else {
    return phone;
  }
};
export var checkIfImageIsAvailable = function checkIfImageIsAvailable(request) {
  var filteredData = [];

  if (!Array.isArray(request)) {
    var _request$images;

    filteredData.push({
      product_sku: request === null || request === void 0 ? void 0 : request.product_sku,
      images: _toConsumableArray(request === null || request === void 0 ? void 0 : (_request$images = request.images) === null || _request$images === void 0 ? void 0 : _request$images.filter(function (img) {
        return !img.url.includes('missing_image');
      }))
    });
  } else {
    filteredData = _toConsumableArray(request.map(function (data) {
      return _objectSpread(_objectSpread({}, data), {}, {
        images: data.images.filter(function (img) {
          return !img.url.includes('missing_image');
        })
      });
    }));
  }

  filteredData = filteredData.filter(function (data) {
    return data.images.length > 0;
  });
  return filteredData;
};
export var generateDownloadUrlSuccess = function generateDownloadUrlSuccess(data) {
  if (data.generateDownloadUrlM.generated_download_url_output) {
    var _data$generateDownloa = data.generateDownloadUrlM.generated_download_url_output,
        status = _data$generateDownloa.status,
        download_url = _data$generateDownloa.download_url;

    if (status === 'ok') {
      window.open(download_url, '_blank');
    }
  }
};
export var scrollToTop = function scrollToTop() {
  return window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};
export var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
export var formatDate = function formatDate(date) {
  var today = new Date(date.replace(/\s/, 'T'));
  var yyyy = today.getFullYear();
  var mm = today.getMonth() + 1;
  var dd = today.getDate();
  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;
  return "".concat(mm, "/").concat(dd, "/").concat(yyyy);
};
export var deriveOptionSelectionsFromProduct = function deriveOptionSelectionsFromProduct(product, INITIAL_OPTION_SELECTIONS) {
  var _product$media_galler, _defaultImageFile, _product$variants$fin;

  if (!isProductConfigurable(product)) {
    return INITIAL_OPTION_SELECTIONS;
  } // Jola customized logic - for HubbardtonForge, the requirement for the configurable products
  // is such that when a visitor lands on the page, some default configuration should be selected.
  // Default configuration should be a product variant derived from the name of the main image
  // in the base/config product. The name of the image should match the value  of the 'hf_smart_string'
  // attribute
  // let defaultImage = product.media_gallery_entries.find(


  var defaultImage = product === null || product === void 0 ? void 0 : (_product$media_galler = product.media_gallery) === null || _product$media_galler === void 0 ? void 0 : _product$media_galler.find(function (image) {
    var _image$url;

    return (image === null || image === void 0 ? void 0 : (_image$url = image.url) === null || _image$url === void 0 ? void 0 : _image$url.split('/').reverse()[0].replace(/^.*?[_]/, '').replace(/\.[^/.]+$/, '').replace(/_[0-9]+?$/, '')) == '1';
  });
  var defaultImageFile = null;

  if (!defaultImage) {
    if (product !== null && product !== void 0 && product.media_gallery) {
      var _product$media_galler2;

      defaultImageFile = product === null || product === void 0 ? void 0 : (_product$media_galler2 = product.media_gallery[0]) === null || _product$media_galler2 === void 0 ? void 0 : _product$media_galler2.url;
    }
  } else {
    defaultImageFile = defaultImage === null || defaultImage === void 0 ? void 0 : defaultImage.url;
  } // const productMainImageFile = product.media_gallery_entries[0]?.file.replace(/^.*[\\\/]/, '');


  var productMainImageFile = (_defaultImageFile = defaultImageFile) === null || _defaultImageFile === void 0 ? void 0 : _defaultImageFile.replace(/^.*[\\\/]/, '');
  var targetSmartString = productMainImageFile === null || productMainImageFile === void 0 ? void 0 : productMainImageFile.substring(0, productMainImageFile.indexOf('_')); // if we cannot match the variant by smart string (maybe its disabled or out of stock/sale)
  // just take the first available variant as a failsafe

  var targetVariant = (_product$variants$fin = product.variants.find(function (variant) {
    var _variant$product;

    return (variant === null || variant === void 0 ? void 0 : (_variant$product = variant.product) === null || _variant$product === void 0 ? void 0 : _variant$product.hf_smart_string) == targetSmartString;
  })) !== null && _product$variants$fin !== void 0 ? _product$variants$fin : product.variants[0];
  var urlArguments = new URLSearchParams(window.location.search);
  var selectedVariant = urlArguments === null || urlArguments === void 0 ? void 0 : urlArguments.get('selected_variant');

  if (selectedVariant) {
    var _product$variants$fin2;

    targetVariant = (_product$variants$fin2 = product.variants.find(function (variant) {
      var _variant$product2;

      return (variant === null || variant === void 0 ? void 0 : (_variant$product2 = variant.product) === null || _variant$product2 === void 0 ? void 0 : _variant$product2.uid) == selectedVariant;
    })) !== null && _product$variants$fin2 !== void 0 ? _product$variants$fin2 : product.variants[0];
  }

  var initialOptionSelections = new Map();

  var _iterator = _createForOfIteratorHelper(product.configurable_options),
      _step;

  try {
    var _loop = function _loop() {
      var _step$value = _step.value,
          attribute_id = _step$value.attribute_id,
          attribute_code = _step$value.attribute_code,
          values = _step$value.values;

      if (targetVariant != undefined) {
        var _targetVariant$attrib;

        // console.log(targetVariant);
        // console.log(targetVariant.attributes.find( attribute => attribute.code == attribute_code ));
        initialOptionSelections.set(attribute_id, (_targetVariant$attrib = targetVariant.attributes.find(function (attribute) {
          return attribute.code == attribute_code;
        })) === null || _targetVariant$attrib === void 0 ? void 0 : _targetVariant$attrib.value_index);
      } else {
        initialOptionSelections.set(attribute_id, values[0].value_index);
      }
    };

    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      _loop();
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  return initialOptionSelections;
};
export var isOutletFromSKU = function isOutletFromSKU(sku) {
  return sku === null || sku === void 0 ? void 0 : sku.startsWith('OUT');
};