import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import Trigger from '@magento/venia-ui/lib/components/Trigger';
import { Form } from 'informed';
import { useSearchBar } from '@magento/peregrine/lib/talons/SearchBar';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Modal, CloseIcon, AppBodyLayout, Button, Image } from '..';
import classes from './searchModal.module.css';
import SearchField from './searchField';
import Price from '@magento/venia-ui/lib/components/Price';
import { useUserContext } from '@magento/peregrine/lib/context/user';
import { useLazyQuery } from '@apollo/client';
import { GET_GROUPED_PRODUCTS_PRICES } from '../../RootComponents/Category/category.gql';
import { calculatePriceRange } from '../../RootComponents/Category/category';

var SearchModal = function SearchModal(_ref) {
  var _products$meta, _products$meta2, _products$meta3, _products$meta4, _products$meta5, _products$meta6;

  var isOpen = _ref.isOpen,
      closeModal = _ref.closeModal,
      onSearchChange = _ref.onSearchChange,
      searchValue = _ref.searchValue,
      setSearchValue = _ref.setSearchValue,
      klevuFocusEvent = _ref.onFocus,
      suggestions = _ref.suggestions,
      products = _ref.products,
      categories = _ref.categories,
      doSearch = _ref.doSearch,
      trendingProducts = _ref.trendingProducts,
      settings = _ref.settings,
      handleSearchTriggerClick = _ref.handleSearchTriggerClick;

  var _useUserContext = useUserContext(),
      _useUserContext2 = _slicedToArray(_useUserContext, 1),
      currentUser = _useUserContext2[0].currentUser;

  var _useState = useState(undefined),
      _useState2 = _slicedToArray(_useState, 2),
      functionToReset = _useState2[0],
      setFunctionToReset = _useState2[1];

  var _useParams = useParams(),
      type = _useParams.type;

  var history = useHistory();
  var searchQueryValue = functionToReset === null || functionToReset === void 0 ? void 0 : functionToReset.api.getValue('search_query');
  var customerGroupId = (currentUser === null || currentUser === void 0 ? void 0 : currentUser.group_uid) !== 0 ? window.atob(currentUser === null || currentUser === void 0 ? void 0 : currentUser.group_uid) : 0;
  var talonProps = useSearchBar();
  var containerRef = talonProps.containerRef,
      handleChange = talonProps.handleChange,
      handleFocus = talonProps.handleFocus,
      handleSubmit = talonProps.handleSubmit,
      initialValues = talonProps.initialValues,
      isAutoCompleteOpen = talonProps.isAutoCompleteOpen,
      setIsAutoCompleteOpen = talonProps.setIsAutoCompleteOpen,
      valid = talonProps.valid;

  var wrappedFocusEvent = function wrappedFocusEvent() {
    handleFocus();
    klevuFocusEvent();
  };

  var suggestionsListItems = useMemo(function () {
    return suggestions.map(function (sug, index) {
      var _sug$replace;

      var suggestion = sug === null || sug === void 0 ? void 0 : (_sug$replace = sug.replace(/<b>/g, '')) === null || _sug$replace === void 0 ? void 0 : _sug$replace.replace(/<\/b>/g, '');
      return suggestion !== searchValue ? React.createElement("li", {
        key: "suggestion-".concat(sug, "-").concat(index),
        className: "mb-2 cursor-pointer text-sm",
        onClick: function onClick() {
          onSearchChange({
            target: {
              value: suggestion
            }
          });
        }
      }, React.createElement("span", {
        dangerouslySetInnerHTML: {
          __html: sug
        }
      })) : React.createElement(Fragment, {
        key: "suggestion-".concat(sug, "-").concat(index)
      });
    });
  }, [suggestions, searchValue, onSearchChange]);
  var categoriesListItems = useMemo(function () {
    return categories === null || categories === void 0 ? void 0 : categories.map(function (cat, index) {
      return React.createElement("li", {
        key: "category-".concat(cat, "-").concat(index),
        className: "mb-4 cursor-pointer"
      }, React.createElement(Link, {
        to: cat === null || cat === void 0 ? void 0 : cat.url,
        onClick: function onClick() {
          cat.clickManager(cat === null || cat === void 0 ? void 0 : cat.id);
          handleSearchTriggerClick();
          closeModal();
        },
        className: "text-sm text-bronze",
        "aria-label": "Go to ".concat(cat === null || cat === void 0 ? void 0 : cat.name)
      }, React.createElement("span", null, cat === null || cat === void 0 ? void 0 : cat.name)));
    });
  }, [categories]);
  var trendingOrSearchProducts = useMemo(function () {
    var _products$items;

    return (products === null || products === void 0 ? void 0 : (_products$items = products.items) === null || _products$items === void 0 ? void 0 : _products$items.length) === 0 ? trendingProducts : (searchQueryValue === null || searchQueryValue === void 0 ? void 0 : searchQueryValue.length) >= 3 ? products === null || products === void 0 ? void 0 : products.items : trendingProducts;
  }, [products, trendingProducts, searchQueryValue === null || searchQueryValue === void 0 ? void 0 : searchQueryValue.length]);

  var _useLazyQuery = useLazyQuery(GET_GROUPED_PRODUCTS_PRICES, {
    fetchPolicy: 'no-cache' // nextFetchPolicy: 'cache-first'

  }),
      _useLazyQuery2 = _slicedToArray(_useLazyQuery, 2),
      runQuery = _useLazyQuery2[0],
      outletPriceRangeData = _useLazyQuery2[1].data;

  var outletSkus = useMemo(function () {
    return trendingOrSearchProducts.filter(function (magentoProduct) {
      return magentoProduct.sku.startsWith('OUT-');
    }).map(function (item) {
      return item.sku;
    });
  }, [trendingOrSearchProducts]);
  useEffect(function () {
    if (outletSkus.length > 0) {
      runQuery({
        variables: {
          skus: outletSkus
        }
      });
    }
  }, [outletSkus, runQuery]);
  var outletPriceRange = useMemo(function () {
    var _outletPriceRangeData, _outletPriceRangeData2;

    return outletPriceRangeData === null || outletPriceRangeData === void 0 ? void 0 : (_outletPriceRangeData = outletPriceRangeData.products) === null || _outletPriceRangeData === void 0 ? void 0 : (_outletPriceRangeData2 = _outletPriceRangeData.items) === null || _outletPriceRangeData2 === void 0 ? void 0 : _outletPriceRangeData2.filter(function (item) {
      var _item$items;

      return item.__typename == 'GroupedProduct' && (item === null || item === void 0 ? void 0 : (_item$items = item.items) === null || _item$items === void 0 ? void 0 : _item$items.length) > 0;
    }).map(function (item) {
      return {
        sku: item.sku,
        price_range: calculatePriceRange(item === null || item === void 0 ? void 0 : item.items)
      };
    }).filter(function (item) {
      return item.price_range;
    });
  }, [outletPriceRangeData]);

  var faqPage = function faqPage() {
    closeModal();
    history.push('/about/faq');
  };

  var contactUsPage = function contactUsPage() {
    closeModal();
    history.push('/about/contact');
  };

  return React.createElement(Modal, {
    isOpen: isOpen,
    size: "full",
    toggleModal: closeModal,
    classes: {
      modalWrapper: classes.root
    }
  }, React.createElement("div", {
    className: "w-full h-full bg-white absolute overflow-y-auto"
  }, React.createElement(Trigger, {
    className: classes.closeBtn,
    action: closeModal,
    "aria-label": "Close modal"
  }, React.createElement("span", {
    className: "sr-only"
  }, "Close modal"), React.createElement(CloseIcon, null)), React.createElement(AppBodyLayout, {
    classes: {
      root: 'mt-20'
    }
  }, React.createElement("div", {
    className: classes.searchFieldWrapper
  }, React.createElement(Form, null, React.createElement(SearchField, {
    isSearchOpen: isOpen,
    onChange: onSearchChange,
    value: searchValue,
    setFunctionToReset: setFunctionToReset,
    onFocus: wrappedFocusEvent,
    classes: {
      input: classes.searchField,
      placeholder: classes.searchField
    },
    klevuValue: searchValue
  }))), React.createElement("div", {
    className: "".concat(classes.klevuSearchWrapper, " mt-16 pb-20")
  }, React.createElement("div", {
    className: "".concat(classes.sidebar)
  }, (suggestionsListItems === null || suggestionsListItems === void 0 ? void 0 : suggestionsListItems.length) > 0 ? React.createElement("div", {
    className: classes.suggestionsDesktop
  }, React.createElement("h5", {
    className: "mb-5"
  }, "Suggestions"), React.createElement("ul", null, suggestionsListItems)) : React.createElement(React.Fragment, null), (categories === null || categories === void 0 ? void 0 : categories.length) > 0 && React.createElement("div", null, React.createElement("h5", {
    className: "mb-5"
  }, "Categories"), React.createElement("ul", null, categoriesListItems)), React.createElement("div", null, React.createElement("h5", {
    className: "mb-5"
  }, "Need Help?"), React.createElement(Button, {
    priority: "low",
    fullWidth: true,
    classes: {
      root: 'mb-5'
    },
    "aria-label": "Go to FAQ page",
    onClick: faqPage
  }, "FAQ"), React.createElement(Button, {
    priority: "low",
    "aria-label": "contact us",
    fullWidth: true,
    onClick: contactUsPage
  }, "CONTACT US"))), React.createElement("div", {
    className: "".concat(classes.products)
  }, React.createElement("div", {
    className: "flex mb-5 justify-between items-center"
  }, React.createElement("div", {
    className: "flex items-center"
  }, React.createElement("h5", {
    className: "".concat(classes.resultsTitle, " leading-156")
  }, (products === null || products === void 0 ? void 0 : (_products$meta = products.meta) === null || _products$meta === void 0 ? void 0 : _products$meta.totalResultsFound) !== undefined ? products !== null && products !== void 0 && (_products$meta2 = products.meta) !== null && _products$meta2 !== void 0 && _products$meta2.totalResultsFound ? 'Product Search Results' : 'No Results Found' : 'Trending Products'), React.createElement("br", null), React.createElement("p", {
    className: "ml-5 text-bronze leading-156 text-sm"
  }, products !== null && products !== void 0 && (_products$meta3 = products.meta) !== null && _products$meta3 !== void 0 && _products$meta3.totalResultsFound ? "".concat(products === null || products === void 0 ? void 0 : (_products$meta4 = products.meta) === null || _products$meta4 === void 0 ? void 0 : _products$meta4.totalResultsFound, " Results") : '')), products.items.length > 0 ? React.createElement(Link, {
    to: {
      pathname: '/search.html',
      search: "query=".concat(searchQueryValue),
      state: []
    },
    onClick: function onClick() {
      handleSearchTriggerClick();
      setSearchValue(undefined);
      closeModal();
    },
    className: "underline text-bronze text-sm"
  }, "View All") : React.createElement(React.Fragment, null)), React.createElement("div", {
    className: "".concat(classes.klevuSearchWrapper)
  }, (suggestionsListItems === null || suggestionsListItems === void 0 ? void 0 : suggestionsListItems.length) > 0 ? React.createElement("div", {
    className: classes.suggestionsMobile
  }, React.createElement("h5", {
    className: "mb-5"
  }, "Suggestions"), React.createElement("ul", null, suggestionsListItems)) : React.createElement(React.Fragment, null)), (products === null || products === void 0 ? void 0 : (_products$meta5 = products.meta) === null || _products$meta5 === void 0 ? void 0 : _products$meta5.totalResultsFound) !== undefined ? products !== null && products !== void 0 && (_products$meta6 = products.meta) !== null && _products$meta6 !== void 0 && _products$meta6.totalResultsFound ? React.createElement(React.Fragment, null) : React.createElement(React.Fragment, null, React.createElement("br", null), React.createElement("p", null, "Viewing trending products")) : React.createElement(React.Fragment, null), React.createElement("div", {
    className: "".concat(classes.productItems, " ")
  }, trendingOrSearchProducts.map(function (item) {
    var _item$id, _item$sku, _priceRange$price_ran, _priceRange$price_ran2, _priceRange$price_ran3, _priceRange$price_ran4, _priceRange$price_ran5, _priceRange$price_ran6, _priceRange$price_ran7, _priceRange$price_ran8, _priceRange$price_ran9, _priceRange$price_ran10, _priceRange$price_ran11, _priceRange$price_ran12, _priceRange$price_ran13, _priceRange$price_ran14, _priceRange$price_ran15, _priceRange$price_ran16, _priceRange$price_ran17, _priceRange$price_ran18, _priceRange$price_ran19, _priceRange$price_ran20, _priceRange$price_ran21, _priceRange$price_ran22, _priceRange$price_ran23, _priceRange$price_ran24;

    var priceRange = outletPriceRange === null || outletPriceRange === void 0 ? void 0 : outletPriceRange.find(function (priceItem) {
      return (priceItem === null || priceItem === void 0 ? void 0 : priceItem.sku) === (item === null || item === void 0 ? void 0 : item.sku);
    });
    var sku = item === null || item === void 0 ? void 0 : item.sku;
    var idSplit = item === null || item === void 0 ? void 0 : (_item$id = item.id) === null || _item$id === void 0 ? void 0 : _item$id.split('-');
    var variantId = window.btoa(idSplit[(idSplit === null || idSplit === void 0 ? void 0 : idSplit.length) - 1]);
    var price = parseFloat(item === null || item === void 0 ? void 0 : item.price);
    var salePrice = parseFloat(item === null || item === void 0 ? void 0 : item.salePrice);
    var customerGroupSalePrice = customerGroupId !== 'USD-0' ? item["salePrice_".concat(customerGroupId)] ? parseFloat(item["salePrice_".concat(customerGroupId)]) : parseFloat(item["salePrice"]) : parseFloat(item["salePrice"]);
    var isOutletProduct = item === null || item === void 0 ? void 0 : (_item$sku = item.sku) === null || _item$sku === void 0 ? void 0 : _item$sku.startsWith('OUT-');
    var showDiscountedPrice = isOutletProduct && parseFloat(priceRange === null || priceRange === void 0 ? void 0 : (_priceRange$price_ran = priceRange.price_range) === null || _priceRange$price_ran === void 0 ? void 0 : (_priceRange$price_ran2 = _priceRange$price_ran.product_min_price) === null || _priceRange$price_ran2 === void 0 ? void 0 : (_priceRange$price_ran3 = _priceRange$price_ran2.regular_price) === null || _priceRange$price_ran3 === void 0 ? void 0 : _priceRange$price_ran3.value) > parseFloat(priceRange === null || priceRange === void 0 ? void 0 : (_priceRange$price_ran4 = priceRange.price_range) === null || _priceRange$price_ran4 === void 0 ? void 0 : (_priceRange$price_ran5 = _priceRange$price_ran4.product_min_price) === null || _priceRange$price_ran5 === void 0 ? void 0 : (_priceRange$price_ran6 = _priceRange$price_ran5.final_price) === null || _priceRange$price_ran6 === void 0 ? void 0 : _priceRange$price_ran6.value);
    var showFrom = isOutletProduct && parseFloat(priceRange === null || priceRange === void 0 ? void 0 : (_priceRange$price_ran7 = priceRange.price_range) === null || _priceRange$price_ran7 === void 0 ? void 0 : (_priceRange$price_ran8 = _priceRange$price_ran7.product_min_price) === null || _priceRange$price_ran8 === void 0 ? void 0 : (_priceRange$price_ran9 = _priceRange$price_ran8.final_price) === null || _priceRange$price_ran9 === void 0 ? void 0 : _priceRange$price_ran9.value) < parseFloat(priceRange === null || priceRange === void 0 ? void 0 : (_priceRange$price_ran10 = priceRange.price_range) === null || _priceRange$price_ran10 === void 0 ? void 0 : (_priceRange$price_ran11 = _priceRange$price_ran10.product_max_price) === null || _priceRange$price_ran11 === void 0 ? void 0 : (_priceRange$price_ran12 = _priceRange$price_ran11.final_price) === null || _priceRange$price_ran12 === void 0 ? void 0 : _priceRange$price_ran12.value);
    var outletMark = isOutletProduct ? React.createElement("div", {
      className: classes.outletMark
    }, "OUTLET") : React.createElement(React.Fragment, null);
    return React.createElement(Link, {
      key: item.sku // to={item.url + '?selected_variant=' + variantId}
      // As per client request on the January 12th (present at the meeting where JMG, Adam and Clement)
      // we're removing the links to point out to specific SKUs and they will always go to the base item 
      ,
      to: item.url,
      className: "border border-solid border-snowbound",
      onClick: function onClick() {
        closeModal();
        item.clickManager(item.id, item.itemGroupId);
        setSearchValue(undefined);
      }
    }, React.createElement("div", {
      className: "relative"
    }, outletMark, React.createElement(Image, {
      height: "200",
      width: "200" // classes={{
      //     root:
      //         classes?.klevu_product_item_image,
      //     placeholder:
      //         classes.klevu_product_item_image_placeholder
      // }}
      ,
      resource: item === null || item === void 0 ? void 0 : item.imageUrl // placeholder={
      //     'https://' +
      //     settings?.klevu_userJavascriptDomain +
      //     settings?.klevu_uc_userOptions?.noImageUrl
      // }
      ,
      alt: item.name,
      classes: {
        root: classes.image,
        loaded: classes.imageLoaded,
        placeholder: classes.placeholder
      }
    }), React.createElement("div", {
      className: "px-3 text-xs text-black"
    }, React.createElement("p", {
      className: "font-semibold leading-267"
    }, item.name), React.createElement("p", {
      className: "font-light"
    }, "Base Item ", sku), !!priceRange && React.createElement(React.Fragment, null, showFrom && React.createElement("span", {
      className: "pr-2"
    }, "From"), showDiscountedPrice && React.createElement(Price, {
      classes: {
        root: 'text-base leading-2 md_leading-171 font-normal text-black line-through inline mr-2'
      },
      value: parseFloat(priceRange === null || priceRange === void 0 ? void 0 : (_priceRange$price_ran13 = priceRange.price_range) === null || _priceRange$price_ran13 === void 0 ? void 0 : (_priceRange$price_ran14 = _priceRange$price_ran13.product_min_price) === null || _priceRange$price_ran14 === void 0 ? void 0 : (_priceRange$price_ran15 = _priceRange$price_ran14.regular_price) === null || _priceRange$price_ran15 === void 0 ? void 0 : _priceRange$price_ran15.value),
      currencyCode: priceRange === null || priceRange === void 0 ? void 0 : (_priceRange$price_ran16 = priceRange.price_range) === null || _priceRange$price_ran16 === void 0 ? void 0 : (_priceRange$price_ran17 = _priceRange$price_ran16.product_min_price) === null || _priceRange$price_ran17 === void 0 ? void 0 : (_priceRange$price_ran18 = _priceRange$price_ran17.regular_price) === null || _priceRange$price_ran18 === void 0 ? void 0 : _priceRange$price_ran18.currency
    }), React.createElement(Price, {
      classes: {
        root: 'text-base leading-2 md_leading-171 font-normal' + (showDiscountedPrice ? ' text-red inline' : '')
      },
      value: parseFloat(priceRange === null || priceRange === void 0 ? void 0 : (_priceRange$price_ran19 = priceRange.price_range) === null || _priceRange$price_ran19 === void 0 ? void 0 : (_priceRange$price_ran20 = _priceRange$price_ran19.product_min_price) === null || _priceRange$price_ran20 === void 0 ? void 0 : (_priceRange$price_ran21 = _priceRange$price_ran20.final_price) === null || _priceRange$price_ran21 === void 0 ? void 0 : _priceRange$price_ran21.value),
      currencyCode: priceRange === null || priceRange === void 0 ? void 0 : (_priceRange$price_ran22 = priceRange.price_range) === null || _priceRange$price_ran22 === void 0 ? void 0 : (_priceRange$price_ran23 = _priceRange$price_ran22.product_min_price) === null || _priceRange$price_ran23 === void 0 ? void 0 : (_priceRange$price_ran24 = _priceRange$price_ran23.final_price) === null || _priceRange$price_ran24 === void 0 ? void 0 : _priceRange$price_ran24.currency
    })), !priceRange && React.createElement(Price, {
      classes: {
        root: 'text-base leading-2 md_leading-171 font-normal' + (showDiscountedPrice ? ' text-red inline' : '')
      },
      value: customerGroupSalePrice > price ? salePrice > price ? price : salePrice : customerGroupSalePrice,
      currencyCode: item === null || item === void 0 ? void 0 : item.currency
    }))));
  })))))));
};

export default SearchModal;
{
  /* <div className="col-span-10 2x_col-span-8 ">
  <h5 className="text-bronze leading-156">No Results Found.</h5>
  <p className="text-bronze text-18 mt-5">
  Sorry, we couldn’t find a match. Please check the spelling or
  try another search
  </p>
  </div> */
}