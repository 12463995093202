import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { KlevuSearchSorting } from '@klevu/core';
var fieldSearchNonLoggedIn = ['id', 'sku', 'name', 'klevu_category', 'image', 'url', 'typeOfRecord', 'hf_depth_length', 'hf_dimensions', 'hf_height', 'hf_width', 'hf_length', 'color', 'inStock', 'url_key', 'itemGroupId', 'hf_overall_height', 'price', 'salePrice', 'currency', 'hf_smart_string', 'hf_children_smart_string', 'hf_new_designs'];
var fieldSearchLoggedIn = ['id', 'sku', 'name', 'klevu_category', 'image', 'url', 'typeOfRecord', 'hf_depth_length', 'hf_dimensions', 'hf_height', 'hf_width', 'hf_length', 'color', 'price', 'salePrice', 'currency', 'inStock', 'url_key', 'itemGroupId', 'hf_overall_height', 'hf_smart_string', 'hf_children_smart_string', 'hf_new_designs'];
var KLEVU_SORT_OPTIONS_LOGGED_IN = [{
  value: KlevuSearchSorting.Relevance,
  label: 'Relevance'
}, {
  value: KlevuSearchSorting.PriceDesc,
  label: 'Price: High to Low'
}, {
  value: KlevuSearchSorting.PriceAsc,
  label: 'Price: Low To High'
}, {
  value: KlevuSearchSorting.NameAsc,
  label: 'Product Name Ascending'
}, {
  value: KlevuSearchSorting.NameDesc,
  label: 'Product Name Descending'
} // {
//     value: KlevuSearchSorting.NewArrivalAsc,
//     label: 'New Arivals Ascending'
// },
// {
//     value: KlevuSearchSorting.NewArrivalDesc,
//     label: 'New Arivals Descending'
// }
];
var KLEVU_SORT_OPTIONS_LOGGED_OUT = [{
  value: KlevuSearchSorting.Relevance,
  label: 'Relevance'
}, {
  value: KlevuSearchSorting.PriceDesc,
  label: 'Price: High to Low'
}, {
  value: KlevuSearchSorting.PriceAsc,
  label: 'Price: Low To High'
}, {
  value: KlevuSearchSorting.NameAsc,
  label: 'Product Name Ascending'
}, {
  value: KlevuSearchSorting.NameDesc,
  label: 'Product Name Descending'
} // {
//     value: KlevuSearchSorting.NewArrivalAsc,
//     label: 'New Arivals Ascending'
// },
// {
//     value: KlevuSearchSorting.NewArrivalDesc,
//     label: 'New Arivals Descending'
// }
];

var KLEVU_FORMAT_PRODUCTS = function KLEVU_FORMAT_PRODUCTS(products, customerGroupId) {
  var _products$items;

  return products === null || products === void 0 ? void 0 : (_products$items = products.items) === null || _products$items === void 0 ? void 0 : _products$items.map(function (item) {
    var _item$sku, _item$id, _item$imageUrl$replac, _parseFloat, _parseFloat$toFixed, _parseFloat$toFixed$r, _parseFloat2, _parseFloat2$toFixed, _parseFloat2$toFixed$, _parseFloat3, _parseFloat3$toFixed, _parseFloat3$toFixed$, _parseFloat4, _parseFloat4$toFixed, _parseFloat4$toFixed$, _parseFloat5, _parseFloat5$toFixed, _parseFloat5$toFixed$, _objectSpread2;

    var skuSplit = item === null || item === void 0 ? void 0 : (_item$sku = item.sku) === null || _item$sku === void 0 ? void 0 : _item$sku.split(';');
    var sku = skuSplit[0];
    var idSplit = item === null || item === void 0 ? void 0 : (_item$id = item.id) === null || _item$id === void 0 ? void 0 : _item$id.split('-');
    var variantId = window.btoa(idSplit[(idSplit === null || idSplit === void 0 ? void 0 : idSplit.length) - 1]);
    var price = parseFloat(item === null || item === void 0 ? void 0 : item.price);
    var salePrice = parseFloat(item === null || item === void 0 ? void 0 : item.salePrice);
    var customerGroupSalePrice = customerGroupId !== 'USD-0' ? item["salePrice_".concat(customerGroupId)] ? parseFloat(item["salePrice_".concat(customerGroupId)]) : parseFloat(item["salePrice"]) : parseFloat(item["salePrice"]);
    return _objectSpread(_objectSpread({}, item), {}, (_objectSpread2 = {
      id: item.id,
      url_key: (item === null || item === void 0 ? void 0 : item.url) + '?selected_variant=' + variantId,
      small_image: {
        url: (_item$imageUrl$replac = item.imageUrl.replace(/.*?\/needtochange/, '')) === null || _item$imageUrl$replac === void 0 ? void 0 : _item$imageUrl$replac.replace('/catalog/product', '')
      },
      hf_dimensions: (_parseFloat = parseFloat(item === null || item === void 0 ? void 0 : item.hf_dimensions)) === null || _parseFloat === void 0 ? void 0 : (_parseFloat$toFixed = _parseFloat.toFixed(2)) === null || _parseFloat$toFixed === void 0 ? void 0 : (_parseFloat$toFixed$r = _parseFloat$toFixed.replace(/0$/, '')) === null || _parseFloat$toFixed$r === void 0 ? void 0 : _parseFloat$toFixed$r.replace(/\.0$/, ''),
      hf_height: (_parseFloat2 = parseFloat(item === null || item === void 0 ? void 0 : item.hf_height)) === null || _parseFloat2 === void 0 ? void 0 : (_parseFloat2$toFixed = _parseFloat2.toFixed(2)) === null || _parseFloat2$toFixed === void 0 ? void 0 : (_parseFloat2$toFixed$ = _parseFloat2$toFixed.replace(/0$/, '')) === null || _parseFloat2$toFixed$ === void 0 ? void 0 : _parseFloat2$toFixed$.replace(/\.0$/, ''),
      hf_width: (_parseFloat3 = parseFloat(item === null || item === void 0 ? void 0 : item.hf_width)) === null || _parseFloat3 === void 0 ? void 0 : (_parseFloat3$toFixed = _parseFloat3.toFixed(2)) === null || _parseFloat3$toFixed === void 0 ? void 0 : (_parseFloat3$toFixed$ = _parseFloat3$toFixed.replace(/0$/, '')) === null || _parseFloat3$toFixed$ === void 0 ? void 0 : _parseFloat3$toFixed$.replace(/\.0$/, ''),
      hf_depth: (_parseFloat4 = parseFloat(item === null || item === void 0 ? void 0 : item.hf_depth)) === null || _parseFloat4 === void 0 ? void 0 : (_parseFloat4$toFixed = _parseFloat4.toFixed(2)) === null || _parseFloat4$toFixed === void 0 ? void 0 : (_parseFloat4$toFixed$ = _parseFloat4$toFixed.replace(/0$/, '')) === null || _parseFloat4$toFixed$ === void 0 ? void 0 : _parseFloat4$toFixed$.replace(/\.0$/, ''),
      hf_depth_length: (_parseFloat5 = parseFloat(item === null || item === void 0 ? void 0 : item.hf_depth_length)) === null || _parseFloat5 === void 0 ? void 0 : (_parseFloat5$toFixed = _parseFloat5.toFixed(2)) === null || _parseFloat5$toFixed === void 0 ? void 0 : (_parseFloat5$toFixed$ = _parseFloat5$toFixed.replace(/0$/, '')) === null || _parseFloat5$toFixed$ === void 0 ? void 0 : _parseFloat5$toFixed$.replace(/\.0$/, ''),
      price_range: {
        maximum_price: item !== null && item !== void 0 && item.currency ? {
          regular_price: {
            currency: item === null || item === void 0 ? void 0 : item.currency,
            value: customerGroupSalePrice > price ? salePrice > price ? price : salePrice : customerGroupSalePrice
          }
        } : {
          regular_price: {
            currency: 'USD',
            value: price
          }
        },
        minimum_price: item !== null && item !== void 0 && item.currency ? {
          regular_price: {
            currency: item === null || item === void 0 ? void 0 : item.currency,
            value: customerGroupSalePrice > price ? salePrice > price ? price : salePrice : customerGroupSalePrice
          }
        } : {
          regular_price: {
            currency: 'USD',
            value: price
          }
        }
      },
      stock_status: (item === null || item === void 0 ? void 0 : item.inStock) === 'yes' ? 'IN_STOCK' : 'OUT_OF_STOCK'
    }, _defineProperty(_objectSpread2, "small_image", {
      url: item.imageUrl
    }), _defineProperty(_objectSpread2, "sku", sku), _defineProperty(_objectSpread2, "__typename", 'SimpleProduct'), _objectSpread2));
  });
};

var KLEVU_TRANSFORM_FILTERS = function KLEVU_TRANSFORM_FILTERS(options, sliders, manager) {
  var _ref;

  return (_ref = [].concat(_toConsumableArray(options), _toConsumableArray(sliders))) === null || _ref === void 0 ? void 0 : _ref.map(function (option) {
    var _option$options, _option$options$map;

    return _objectSpread(_objectSpread({}, option), {}, {
      attribute_code: option === null || option === void 0 ? void 0 : option.key,
      manager: manager,
      options: option === null || option === void 0 ? void 0 : (_option$options = option.options) === null || _option$options === void 0 ? void 0 : (_option$options$map = _option$options.map(function (o) {
        var _o$name, _parseFloat6, _parseFloat6$toFixed, _parseFloat6$toFixed$;

        return _objectSpread(_objectSpread({}, o), {}, {
          label: o !== null && o !== void 0 && (_o$name = o.name) !== null && _o$name !== void 0 && _o$name.includes('000') ? ((_parseFloat6 = parseFloat(o === null || o === void 0 ? void 0 : o.name)) === null || _parseFloat6 === void 0 ? void 0 : (_parseFloat6$toFixed = _parseFloat6.toFixed(2)) === null || _parseFloat6$toFixed === void 0 ? void 0 : (_parseFloat6$toFixed$ = _parseFloat6$toFixed.replace(/0$/, '')) === null || _parseFloat6$toFixed$ === void 0 ? void 0 : _parseFloat6$toFixed$.replace(/\.0$/, '').toString()) + '"' : o === null || o === void 0 ? void 0 : o.name
        });
      })) === null || _option$options$map === void 0 ? void 0 : _option$options$map.sort(function (a, b) {
        return (a === null || a === void 0 ? void 0 : a.value) - (b === null || b === void 0 ? void 0 : b.value);
      })
    });
  });
};

export { fieldSearchNonLoggedIn, fieldSearchLoggedIn, KLEVU_SORT_OPTIONS_LOGGED_IN, KLEVU_SORT_OPTIONS_LOGGED_OUT, KLEVU_FORMAT_PRODUCTS, KLEVU_TRANSFORM_FILTERS };