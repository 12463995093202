import React from 'react';
import { render } from 'react-dom';
import { KlevuConfig, KlevuKMCSettings } from '@klevu/core';
import store from './store/store';
import app from '@magento/peregrine/lib/store/actions/app';
import Adapter from './components/Adapter';
import { registerSW } from './registerSW';
import './index.css';
import * as Sentry from '@sentry/browser';
import { ReportingObserver as ReportingObserverIntegration } from '@sentry/integrations'; // console.log(process.env.KLEVU_API_KEY, process.env.__DEV__);

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [new ReportingObserverIntegration()]
  });
}

KlevuConfig.init({
  // url: 'https://eucs30v2.ksearchnet.com/cs/v2/search',
  url: process.env.KLEVU_SEARCH_API_ENDPOINT,
  apiKey: process.env.KLEVU_API_KEY
});
KlevuKMCSettings(); // server rendering differs from browser rendering

var isServer = !globalThis.document; // TODO: on the server, the http request should provide the origin

var origin = isServer ? process.env.MAGENTO_BACKEND_URL : globalThis.location.origin; // on the server, components add styles to this set and we render them in bulk

var styles = new Set();
var tree = React.createElement(Adapter, {
  origin: origin,
  store: store,
  styles: styles
});

if (isServer) {
  // TODO: ensure this actually renders correctly
  import('react-dom/server').then(function (_ref) {
    var ReactDOMServer = _ref["default"];
    console.log(ReactDOMServer.renderToString(tree));
  });
} else {
  render(tree, document.getElementById('root'));
  registerSW();
  globalThis.addEventListener('online', function () {
    store.dispatch(app.setOnline());
  });
  globalThis.addEventListener('offline', function () {
    store.dispatch(app.setOffline());
  });
}