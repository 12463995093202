import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import React, { useMemo, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import classes from './collectionSlider.module.css';
import { ProductSliderItem, Slider } from '..';

var CollectionSlider = function CollectionSlider(_ref) {
  var collection = _ref.collection,
      isUltraSmallDevice = _ref.isUltraSmallDevice,
      itemPosition = _ref.itemPosition,
      isMobile = _ref.isMobile;
  var crosssell_products = collection.crosssell_products,
      custom_attributes = collection.custom_attributes;

  var _useState = useState(1),
      _useState2 = _slicedToArray(_useState, 2),
      numberOfDots = _useState2[0],
      setNumberOfDots = _useState2[1];

  var dotsRef = useRef();

  var _useState3 = useState(''),
      _useState4 = _slicedToArray(_useState3, 2),
      align = _useState4[0],
      setAlign = _useState4[1];

  useEffect(function () {
    if (dotsRef.current) setNumberOfDots(dotsRef.current.childNodes.length);
    return function () {
      return setAlign('');
    };
  }, []);
  var collectionName = useMemo(function () {
    var _nameAttribute$select, _nameAttribute$select2, _nameAttribute$select3;

    var nameAttribute = custom_attributes === null || custom_attributes === void 0 ? void 0 : custom_attributes.find(function (customAttribute) {
      var _customAttribute$attr;

      return (customAttribute === null || customAttribute === void 0 ? void 0 : (_customAttribute$attr = customAttribute.attribute_metadata) === null || _customAttribute$attr === void 0 ? void 0 : _customAttribute$attr.code) === 'hf_family';
    });
    return nameAttribute === null || nameAttribute === void 0 ? void 0 : (_nameAttribute$select = nameAttribute.selected_attribute_options) === null || _nameAttribute$select === void 0 ? void 0 : (_nameAttribute$select2 = _nameAttribute$select.attribute_option) === null || _nameAttribute$select2 === void 0 ? void 0 : (_nameAttribute$select3 = _nameAttribute$select2[0]) === null || _nameAttribute$select3 === void 0 ? void 0 : _nameAttribute$select3.label;
  }, [crosssell_products]);
  var collectionLink = useMemo(function () {
    var _collectionName$toLow;

    if (!collectionName) return '';
    return '/collection-landing/' + (collectionName === null || collectionName === void 0 ? void 0 : (_collectionName$toLow = collectionName.toLowerCase()) === null || _collectionName$toLow === void 0 ? void 0 : _collectionName$toLow.replaceAll('+', '').replaceAll(' ', '-').replaceAll('--', '-'));
  }, [collectionName]);
  useEffect(function () {
    if ((filteredCollection === null || filteredCollection === void 0 ? void 0 : filteredCollection.length) > 3) {
      setAlign('');
      return;
    }

    var row = document.querySelectorAll('.products-row > div');
    var findIndex;
    Array.from(row).map(function (row, i) {
      if (row.className.includes('active')) findIndex = i;
    });
    if (findIndex % 3 === 0) setAlign({
      marginRight: 'auto'
    });else if (findIndex % 3 === 1) setAlign({
      margin: '2.5rem auto 0'
    });else setAlign({
      marginLeft: 'auto'
    });
  }, [collection, filteredCollection]);
  var filteredCollection = crosssell_products.filter(function (item) {
    return !item.sku.startsWith('OUT-');
  });
  var content = useMemo(function () {
    return React.createElement(Slider, {
      classes: {
        carousel: classes.carousel
      },
      hideArrows: isMobile ? false : true
    }, filteredCollection.map(function (item, idx) {
      return React.createElement("div", {
        key: idx,
        className: "".concat(classes.sliderItem, " ").concat(!isMobile && (filteredCollection === null || filteredCollection === void 0 ? void 0 : filteredCollection.length) < 4 ? (filteredCollection === null || filteredCollection === void 0 ? void 0 : filteredCollection.length) === 3 ? classes.sliderThreeItems : filteredCollection.length === 2 ? classes.sliderTwoItems : classes.sliderOneItem : '')
      }, React.createElement(ProductSliderItem, {
        item: item
      }));
    }));
  }, [filteredCollection]);
  var overview = React.createElement(Link, {
    className: "underline text-bronze font-bold",
    to: collectionLink,
    "aria-label": "Go to ".concat(collectionName, " Collection")
  }, "Collection Overview");
  return React.createElement("div", {
    className: "".concat(classes.root, "\n            ", 'col-span-1 md_col-span-3'),
    style: _objectSpread({
      maxWidth: (filteredCollection === null || filteredCollection === void 0 ? void 0 : filteredCollection.length) < 4 && !isMobile ? "".concat((filteredCollection === null || filteredCollection === void 0 ? void 0 : filteredCollection.length) === 1 ? '25%' : (filteredCollection === null || filteredCollection === void 0 ? void 0 : filteredCollection.length) === 2 ? '50%' : '75%') : 'fit-content'
    }, align)
  }, React.createElement("div", {
    className: "flex ".concat(isMobile ? 'justify-center' : 'justify-between', " ").concat(filteredCollection.length >= 3 ? 'xl_mr-11' : '', " items-center px-2 md_px-0")
  }, React.createElement("h4", {
    className: "uppercase font-bold"
  }, collectionName), !isMobile ? overview : null), content, React.createElement("div", {
    className: "flex justify-center mt-6"
  }, isMobile ? overview : null));
};

export default CollectionSlider;