import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import React, { Fragment, useEffect, useMemo } from 'react';
import { number, shape, string } from 'prop-types';
import { useCategory } from '@magento/peregrine/lib/talons/RootComponents/Category';
import ErrorView from '@magento/venia-ui/lib/components/ErrorView';
import { Meta } from '@magento/venia-ui/lib/components/Head';
import CategoryContent from './categoryContent';
import defaultClasses from './category.module.css';
import { GET_PAGE_SIZE, GET_GROUPED_PRODUCTS_PRICES } from './category.gql';
import UseKlevuSearchResults, { useQuery as useKlevuQuery } from '../../overrides/venia-ui/components/SearchPage/useKlevuSearchResults';
import { useBreadcrumbs } from '@magento/peregrine/lib/talons/Breadcrumbs/useBreadcrumbs';
import { useUserContext } from '@magento/peregrine/lib/context/user';
import fetchCustomAttribute from '../../overrides/venia-ui/components/SearchPage/customAttributeFetch';
import { useLazyQuery } from '@apollo/client';
export var calculatePriceRange = function calculatePriceRange(priceData) {
  var _priceData$, _priceData$$product, _priceData$$product$p, _priceData$2, _priceData$2$product, _priceData$2$product$;

  var product_minimum_price = priceData === null || priceData === void 0 ? void 0 : (_priceData$ = priceData[0]) === null || _priceData$ === void 0 ? void 0 : (_priceData$$product = _priceData$.product) === null || _priceData$$product === void 0 ? void 0 : (_priceData$$product$p = _priceData$$product.price_range) === null || _priceData$$product$p === void 0 ? void 0 : _priceData$$product$p.minimum_price;
  var product_maximum_price = priceData === null || priceData === void 0 ? void 0 : (_priceData$2 = priceData[0]) === null || _priceData$2 === void 0 ? void 0 : (_priceData$2$product = _priceData$2.product) === null || _priceData$2$product === void 0 ? void 0 : (_priceData$2$product$ = _priceData$2$product.price_range) === null || _priceData$2$product$ === void 0 ? void 0 : _priceData$2$product$.minimum_price;
  priceData.forEach(function (item) {
    if (item !== null && item !== void 0 && item.product) {
      var _product_minimum_pric, _product_minimum_pric2, _item$product, _item$product$price_r, _item$product$price_r2, _item$product$price_r3, _item$product2, _item$product2$price_, _product_maximum_pric, _product_maximum_pric2, _item$product3, _item$product3$price_, _item$product3$price_2, _item$product3$price_3, _item$product4, _item$product4$price_;

      product_minimum_price = ((_product_minimum_pric = product_minimum_price) === null || _product_minimum_pric === void 0 ? void 0 : (_product_minimum_pric2 = _product_minimum_pric.final_price) === null || _product_minimum_pric2 === void 0 ? void 0 : _product_minimum_pric2.value) < (item === null || item === void 0 ? void 0 : (_item$product = item.product) === null || _item$product === void 0 ? void 0 : (_item$product$price_r = _item$product.price_range) === null || _item$product$price_r === void 0 ? void 0 : (_item$product$price_r2 = _item$product$price_r.minimum_price) === null || _item$product$price_r2 === void 0 ? void 0 : (_item$product$price_r3 = _item$product$price_r2.final_price) === null || _item$product$price_r3 === void 0 ? void 0 : _item$product$price_r3.value) ? product_minimum_price : item === null || item === void 0 ? void 0 : (_item$product2 = item.product) === null || _item$product2 === void 0 ? void 0 : (_item$product2$price_ = _item$product2.price_range) === null || _item$product2$price_ === void 0 ? void 0 : _item$product2$price_.minimum_price;
      product_maximum_price = ((_product_maximum_pric = product_maximum_price) === null || _product_maximum_pric === void 0 ? void 0 : (_product_maximum_pric2 = _product_maximum_pric.final_price) === null || _product_maximum_pric2 === void 0 ? void 0 : _product_maximum_pric2.value) > (item === null || item === void 0 ? void 0 : (_item$product3 = item.product) === null || _item$product3 === void 0 ? void 0 : (_item$product3$price_ = _item$product3.price_range) === null || _item$product3$price_ === void 0 ? void 0 : (_item$product3$price_2 = _item$product3$price_.minimum_price) === null || _item$product3$price_2 === void 0 ? void 0 : (_item$product3$price_3 = _item$product3$price_2.final_price) === null || _item$product3$price_3 === void 0 ? void 0 : _item$product3$price_3.value) ? product_minimum_price : item === null || item === void 0 ? void 0 : (_item$product4 = item.product) === null || _item$product4 === void 0 ? void 0 : (_item$product4$price_ = _item$product4.price_range) === null || _item$product4$price_ === void 0 ? void 0 : _item$product4$price_.minimum_price;
    }
  }); // if (product_maximum_price?.final_price?.value == product_minimum_price?.final_price?.value) return false;

  return {
    product_min_price: product_minimum_price,
    product_max_price: product_maximum_price
  };
};

var Category = function Category(props) {
  var _klevuProducts$meta, _klevuProducts$meta2;

  var _useUserContext = useUserContext(),
      _useUserContext2 = _slicedToArray(_useUserContext, 1),
      _useUserContext2$ = _useUserContext2[0],
      isSignedIn = _useUserContext2$.isSignedIn,
      currentUser = _useUserContext2$.currentUser;

  var uid = props.uid;
  var query = useKlevuQuery();
  var talonProps = useCategory({
    id: uid,
    queries: {
      getPageSize: GET_PAGE_SIZE
    }
  });

  var _fetchCustomAttribute = fetchCustomAttribute(),
      swatchData = _fetchCustomAttribute.data,
      swatchLoading = _fetchCustomAttribute.loading,
      swatchError = _fetchCustomAttribute.error;

  var customerGroupId = (currentUser === null || currentUser === void 0 ? void 0 : currentUser.group_uid) !== 0 ? window.atob(currentUser === null || currentUser === void 0 ? void 0 : currentUser.group_uid) : 0;
  var talonPropsBreadcrumbs = useBreadcrumbs({
    categoryId: uid
  });
  var klevuQueryString = useMemo(function () {
    if (!(talonPropsBreadcrumbs !== null && talonPropsBreadcrumbs !== void 0 && talonPropsBreadcrumbs.isLoading)) {
      var _talonPropsBreadcrumb;

      var klevuBreadcrumbs = talonPropsBreadcrumbs === null || talonPropsBreadcrumbs === void 0 ? void 0 : (_talonPropsBreadcrumb = talonPropsBreadcrumbs.normalizedData) === null || _talonPropsBreadcrumb === void 0 ? void 0 : _talonPropsBreadcrumb.map(function (cat) {
        return cat === null || cat === void 0 ? void 0 : cat.text;
      });
      klevuBreadcrumbs.push(talonPropsBreadcrumbs === null || talonPropsBreadcrumbs === void 0 ? void 0 : talonPropsBreadcrumbs.currentCategory);
      return klevuBreadcrumbs.join(';');
    } else {
      return undefined;
    }
  }, [talonPropsBreadcrumbs === null || talonPropsBreadcrumbs === void 0 ? void 0 : talonPropsBreadcrumbs.currentCategory, talonPropsBreadcrumbs === null || talonPropsBreadcrumbs === void 0 ? void 0 : talonPropsBreadcrumbs.normalizedData, talonPropsBreadcrumbs === null || talonPropsBreadcrumbs === void 0 ? void 0 : talonPropsBreadcrumbs.isLoading]);

  var _UseKlevuSearchResult = UseKlevuSearchResults(isSignedIn, customerGroupId, {
    type: 'category',
    query_string: klevuQueryString,
    can_search: !(talonPropsBreadcrumbs !== null && talonPropsBreadcrumbs !== void 0 && talonPropsBreadcrumbs.isLoading),
    title: talonPropsBreadcrumbs === null || talonPropsBreadcrumbs === void 0 ? void 0 : talonPropsBreadcrumbs.currentCategory,
    query: query
  }),
      klevuProducts = _UseKlevuSearchResult.products,
      klevuFilters = _UseKlevuSearchResult.options,
      magentoProducts = _UseKlevuSearchResult.magentoProducts,
      klevuManager = _UseKlevuSearchResult.manager,
      klevuSortingOptions = _UseKlevuSearchResult.klevuSortingOptions,
      sorting = _UseKlevuSearchResult.sorting,
      setSorting = _UseKlevuSearchResult.setSorting,
      klevuLoading = _UseKlevuSearchResult.klevuLoading,
      pageSize = _UseKlevuSearchResult.pageSize,
      setPageSize = _UseKlevuSearchResult.setPageSize;

  var _useLazyQuery = useLazyQuery(GET_GROUPED_PRODUCTS_PRICES, {
    fetchPolicy: 'no-cache' // nextFetchPolicy: 'cache-first'

  }),
      _useLazyQuery2 = _slicedToArray(_useLazyQuery, 2),
      runQuery = _useLazyQuery2[0],
      outletPriceRangeData = _useLazyQuery2[1].data;

  var totalPagesFromData = Math.ceil((klevuProducts === null || klevuProducts === void 0 ? void 0 : (_klevuProducts$meta = klevuProducts.meta) === null || _klevuProducts$meta === void 0 ? void 0 : _klevuProducts$meta.totalResultsFound) / (klevuProducts === null || klevuProducts === void 0 ? void 0 : (_klevuProducts$meta2 = klevuProducts.meta) === null || _klevuProducts$meta2 === void 0 ? void 0 : _klevuProducts$meta2.noOfResults));
  var outletSkus = useMemo(function () {
    return magentoProducts === null || magentoProducts === void 0 ? void 0 : magentoProducts.filter(function (magentoProduct) {
      return magentoProduct.sku.startsWith('OUT-');
    }).map(function (item) {
      return item.sku;
    });
  }, [magentoProducts]);
  useEffect(function () {
    if ((outletSkus === null || outletSkus === void 0 ? void 0 : outletSkus.length) > 0) {
      runQuery({
        variables: {
          skus: outletSkus
        }
      });
    }
  }, [outletSkus, runQuery]);
  var outletPriceRange = useMemo(function () {
    var _outletPriceRangeData, _outletPriceRangeData2;

    return outletPriceRangeData === null || outletPriceRangeData === void 0 ? void 0 : (_outletPriceRangeData = outletPriceRangeData.products) === null || _outletPriceRangeData === void 0 ? void 0 : (_outletPriceRangeData2 = _outletPriceRangeData.items) === null || _outletPriceRangeData2 === void 0 ? void 0 : _outletPriceRangeData2.filter(function (item) {
      var _item$items;

      return item.__typename == 'GroupedProduct' && (item === null || item === void 0 ? void 0 : (_item$items = item.items) === null || _item$items === void 0 ? void 0 : _item$items.length) > 0;
    }).map(function (item) {
      var _item$items2;

      return {
        sku: item.sku,
        price_range: calculatePriceRange(item === null || item === void 0 ? void 0 : (_item$items2 = item.items) === null || _item$items2 === void 0 ? void 0 : _item$items2.filter(function (item) {
          var _item$product5;

          return item === null || item === void 0 ? void 0 : (_item$product5 = item.product) === null || _item$product5 === void 0 ? void 0 : _item$product5.price_range;
        }))
      };
    }).filter(function (item) {
      return item === null || item === void 0 ? void 0 : item.price_range;
    });
  }, [outletPriceRangeData]);
  var transformedFilters = useMemo(function () {
    return klevuFilters.map(function (kItem) {
      var _kItem$options;

      var options = kItem === null || kItem === void 0 ? void 0 : (_kItem$options = kItem.options) === null || _kItem$options === void 0 ? void 0 : _kItem$options.map(function (option) {
        var _swatchData$customAtt, _swatchData$customAtt2, _swatchData$customAtt3, _swatchData$customAtt4, _swatchData$customAtt5;

        return _objectSpread(_objectSpread({}, option), {}, {
          swatch_data: swatchData === null || swatchData === void 0 ? void 0 : (_swatchData$customAtt = swatchData.customAttributeMetadata) === null || _swatchData$customAtt === void 0 ? void 0 : (_swatchData$customAtt2 = _swatchData$customAtt.items) === null || _swatchData$customAtt2 === void 0 ? void 0 : (_swatchData$customAtt3 = _swatchData$customAtt2.find(function (item) {
            return (item === null || item === void 0 ? void 0 : item.attribute_code) === (item === null || item === void 0 ? void 0 : item.attribute_code);
          })) === null || _swatchData$customAtt3 === void 0 ? void 0 : (_swatchData$customAtt4 = _swatchData$customAtt3.attribute_options) === null || _swatchData$customAtt4 === void 0 ? void 0 : (_swatchData$customAtt5 = _swatchData$customAtt4.find(function (attributeOption) {
            return attributeOption.label === option.value;
          })) === null || _swatchData$customAtt5 === void 0 ? void 0 : _swatchData$customAtt5.swatch_data
        });
      });
      kItem.options = options;
      return kItem;
    });
  }, [klevuFilters, swatchData]);
  var error = talonProps.error,
      metaDescription = talonProps.metaDescription,
      loading = talonProps.loading,
      categoryData = talonProps.categoryData,
      pageControl = talonProps.pageControl,
      sortProps = talonProps.sortProps,
      categoryNotFound = talonProps.categoryNotFound,
      pageSizeValues = talonProps.pageSizeValues;

  if (!categoryData) {
    if (error && pageControl.currentPage === 1) {
      if (process.env.NODE_ENV !== 'production') {
        console.error(error);
      }

      return React.createElement(ErrorView, null);
    }
  }

  return React.createElement(Fragment, null, React.createElement(Meta, {
    name: "description",
    content: metaDescription
  }), React.createElement(CategoryContent, {
    categoryId: uid,
    classes: defaultClasses,
    data: categoryData,
    isLoading: swatchLoading || klevuLoading,
    pageControl: pageControl,
    sortProps: sortProps,
    pageSize: pageSize,
    setPageSize: setPageSize,
    transformedFilters: transformedFilters,
    magentoProducts: magentoProducts,
    klevuSortingOptions: klevuSortingOptions,
    klevuFilters: klevuFilters,
    klevuManager: klevuManager,
    sorting: sorting,
    setSorting: setSorting,
    query: query,
    klevuLoading: klevuLoading,
    totalPagesFromData: totalPagesFromData,
    klevuProducts: klevuProducts,
    pageSizeValues: pageSizeValues,
    outletPriceRange: outletPriceRange
  }));
};

Category.propTypes = {
  classes: shape({
    gallery: string,
    root: string,
    title: string
  }),
  uid: string
};
Category.defaultProps = {
  uid: 'Mg=='
};
export default Category;